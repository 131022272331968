
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Typography, Divider } from "@mui/material";
import { auth } from "lib/firebase";
import DialogForgottenPassword from "components/DialogForgottenPassword";
import { Formik, Form } from "formik";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GithubAuthProvider,
} from "firebase/auth";
import { useRouter } from "next/router";
import makeStyles from "@mui/styles/makeStyles";
import useTranslation from "next-translate/useTranslation";
import LoginForm from "components/LoginForm";
// import GoogleConnect from "components/GoogleConnect";
import LoginLayout from "components/LoginLayout";
import ButtonLink from "components/ButtonLink";
import useNotify from "hooks/useNotify";
// import WarningIcon from "@mui/icons-material/Warning";
import useAuthenticatedQuery from "hooks/useAuthenticatedQuery";
import PageLoaderLarge from "components/PageLoaderLarge";
import Cookies from "universal-cookie";
import { useAppContext } from "../contexts/BrandContext";
import GitHubIcon from "@mui/icons-material/GitHub";

const useStyles = makeStyles({
  button: {
    fontWeight: 900,
    fontSize: "18px",
    background: "#00458b",
    textTransform: "none",
  },
  flex: {
    flex: "1 0 0",
  },
  text: {
    fontSize: "15px",
  },
});

export default function LoginPage() {
  const { notify } = useNotify();
  const classes = useStyles();
  const router = useRouter();
  const { query } = useRouter();
  const { push } = router;
  const { t } = useTranslation("common");
  const [currentDialog, setCurrentDialog] = useState(null);
  const [brand, setBrand] = React.useState(null);
  const mycontext = useAppContext();
  useEffect(() => {
    // Call api only if user contains data.
    if (mycontext.brand) {
      setBrand(mycontext.brand);
    }
  }, [mycontext]);

  const handleClose = () => {
    setCurrentDialog(null);
  };

  const { data: dataApplication } = useAuthenticatedQuery(
    `/api/applications/${
      query?.applicationId ?? null
    }/team/invitation/informations?userId=${query?.userId ?? null}`,
    {
      staleTime: 5000,
      retry: false,
      enabled: !!query?.userId && !!query?.applicationId,
    }
  );

  const { data: dataOrganization } = useAuthenticatedQuery(
    `/api/team/invitation/informations?organizationId=${
      query?.organizationId ?? null
    }&userId=${query?.userId ?? null}`,
    {
      staleTime: 5000,
      retry: false,
      enabled: !!query?.userId && !!query?.organizationId,
    }
  );

  // useEffect(() => {
  //   if (!query || Object.keys(query).length === 0) {
  //     //console.log("no query");
  //     return;
  //   }
  //   onAuthStateChanged(auth, async (user) => {
  //     console.log("onAuthStateChanged from Login", query);
  //     if (user?.loadingUser) {
  //       //console.log("loading user");
  //       return null;
  //     }
  //     if (user?.accessToken) {
  //       console.log("user token");
  //       if (query?.applicationId) {
  //         push(
  //           `/invitation-application?applicationId=${query?.applicationId}&userId=${query?.userId}`
  //         );
  //         return;
  //       }
  //       if (query?.organizationId) {
  //         push(
  //           `/invitation?organizationId=${query?.organizationId}&userId=${query?.userId}`
  //         );
  //         return;
  //       }
  //     }
  //   });
  // }, [push, query]);

  const handleSubmit = async (values, setSubmitting) => {
    // Remove impersonate token
    const cookies = new Cookies();
    cookies.remove("impersonateToken", { path: "/" });
    try {
      await signInWithEmailAndPassword(auth, values.login, values.password);
      notify(t("connected"), "success");
      setSubmitting(false);
      query.status = "redirected";
      push({
        pathname: "/login",
        query: query,
      });
    } catch (err) {
      notify(t("wrong"), "error");
    }
  };

  const handleGithubLogin = async () => {
    const provider = new GithubAuthProvider();
    // Add all required scopes
    provider.addScope("user");
    provider.addScope("user:email");
    provider.addScope("repo");

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GithubAuthProvider.credentialFromResult(result);
      if (credential) {
        const token = credential.accessToken;
        const githubUser = result.user;

        // Get user profile from GitHub
        const userResponse = await fetch("https://api.github.com/user", {
          headers: {
            Accept: "application/vnd.github.v3+json",
            Authorization: `Bearer ${token}`,
          },
        });
        const githubProfile = await userResponse.json();

        // Use email from Firebase user if GitHub email is not accessible
        const email = githubUser.email || githubProfile.email;

        // Get first and last name from GitHub profile or Firebase user
        const [firstName = "", lastName = ""] = (
          githubProfile.name ||
          githubUser.displayName ||
          ""
        ).split(" ");

        await fetch("/api/profile", {
          method: "PUT",
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            email: email,
            githubToken: token,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await githubUser.getIdToken()}`,
          },
        });

        notify(t("connected"), "success");
        query.status = "redirected";
        push({
          pathname: "/login",
          query: query,
        });
      }
    } catch (error) {
      console.error("GitHub Auth Error:", error);
      notify(t("wrong"), "error");
    }
  };

  if (query.status === "redirected") {
    return <PageLoaderLarge />;
  }

  return (
    <LoginLayout title={t("login")} brand={brand}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        initialValues={{
          login: "",
          password: "",
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {query?.session === "expired" && (
              <Box mb={2}>
                <Alert severity="error" color="error">
                  {t("your-session-has-expired-please-log-in-again")}
                </Alert>
              </Box>
            )}
            {query?.session === "email" && (
              <Box mb={2}>
                <Alert severity="error" color="error">
                  {t("please-verify-your-email-address-to-be-able-to-login")}
                </Alert>
              </Box>
            )}
            {query?.userId && (
              <Box mb={2}>
                <Alert severity="warning" color="warning">
                  {t(
                    "please-login-with-your-x-account-to-accept-or-reject-the-invitation",
                    {
                      email: query?.applicationId
                        ? dataApplication?.email
                        : dataOrganization?.email,
                    }
                  )}
                </Alert>
              </Box>
            )}
            <Box px={0.65} mt={1.5} mb={3}>
              <LoginForm />
            </Box>
            <Box display="flex" alignItems="center">
              <Box flex="1">
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  flex="1"
                  fullWidth
                >
                  {t("submit-connection")}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => setCurrentDialog("FORGOTTEN")}
                  underline="none"
                  variant="text"
                  size="small"
                  sx={{ ml: 4, background: "transparent" }}
                >
                  {t("password-forgotten")}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Box>
        <Divider textAlign="center" sx={{ flex: "1", mt: 4, mb: 4 }}>
          {t("or")}
        </Divider>
        <Button
          onClick={handleGithubLogin}
          startIcon={<GitHubIcon />}
          fullWidth
          variant="outlined"
        >
          {t("login-with-github")}
        </Button>
      </Box>
      <Box mt={6}>
        <Typography variant="h5" mb={1.5}>
          {t("not-registered")}
        </Typography>
        <ButtonLink
          mt="2"
          href={
            query?.redirectLink
              ? `/register?redirectLink=${query?.redirectLink}`
              : "/register"
          }
          variant="outlined"
          color="secondary"
        >
          {t("create-account")}
        </ButtonLink>
      </Box>
      <DialogForgottenPassword
        open={currentDialog === "FORGOTTEN"}
        handleClose={handleClose}
      />
    </LoginLayout>
  );
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  