import React, { useEffect } from "react";
import { auth } from "lib/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import useNotify from "hooks/useNotify";
import useTranslation from "next-translate/useTranslation";
import { useAppContext } from "../contexts/BrandContext";
import useUser from "hooks/useUser";
import { useRouter } from "next/router";

import * as Yup from "yup";

export default function DialogForgottenPassword({ open, handleClose }) {
  const { t } = useTranslation("common");
  const { notify } = useNotify();
  const [brand, setBrand] = React.useState(null);
  const user = useUser();
  const { locale } = useRouter();
  const mycontext = useAppContext();
  useEffect(() => {
    // Call api only if user contains data.
    if (mycontext.brand) {
      setBrand(mycontext.brand);
    }
  }, [mycontext]);

  const inviteSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      if (values.email) {
        if (brand !== "faaasterpro") {
          // Send email to reset password
          sendPasswordResetEmail(auth, values.email)
            .then(() => {
              notify(t("reset-password-email-success"), "success");
              handleClose();
            })
            .catch(() => {
              notify(t("reset-password-email-error"), "error");
            });
          return;
        } else {
          const res = await fetch(`/api/pros/password`, {
            method: "PUT",
            body: JSON.stringify({
              email: values.email,
              locale: locale,
            }),
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
              "Content-Type": "application/json",
            },
          });
          if (res.status == 200 || res.status == 201) {
            notify(t("reset-password-email-success"), "success");
          } else if (res.status == 429) {
            notify(t("too-many-requests"), "error");
          } else {
            notify(t("reset-password-email-error"), "error");
          }
          return;
        }
      }
      notify(t("reset-password-email-error"), "error");
    } catch (err) {
      console.log(err);
      notify(t("reset-password-email-error"), "error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={inviteSchema}
        initialValues={{
          email: "",
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle variant="h4">
              {t("enter-your-verification-email-address")}
            </DialogTitle>
            <DialogContent dividers>
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexDirection="column"
                py={2}
              >
                <Field
                  component={TextField}
                  label={t("contact.email")}
                  type="text"
                  color="primary"
                  name="email"
                  required
                  sx={{ width: 400 }}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Box display="flex" py={1}>
                <Button
                  color="primary"
                  onClick={handleClose}
                  size="large"
                  sx={{ mr: 2 }}
                >
                  {t("close")}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={10} /> : null
                  }
                >
                  {t("validate")}
                </Button>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
