import React, { useState } from "react";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import useTranslation from "next-translate/useTranslation";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function LoginForm({ loginEmail }) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("common");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box className="loginbox">
      <Field
        mb={2}
        sx={{ marginBottom: 4 }}
        component={TextField}
        required
        InputLabelProps={{ required: false }}
        //InputProps={{ required: false }}
        label={t("contact.email")}
        name="login"
        type="text"
        color="primary"
        variant="outlined"
        disabled={loginEmail ? true : false}
        fullWidth
      />
      <Field
        component={TextField}
        required
        label={t("contact.password")}
        name="password"
        color="primary"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
